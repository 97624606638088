import axios from 'axios';

// 'https://server.api-rankingi-rubiart.pl' 'http://localhost:3000'
axios.defaults.baseURL = 'https://rubiart.pawelduraj.ovh';
axios.defaults.withCredentials = true;

axios.interceptors.request.use((request) => {
    request.headers.Authorization = 'Bearer: ' + localStorage.getItem('token');
    return request;
});

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response && error.response.status === 403 && !window.location.href.endsWith('/admin/login')) {
        localStorage.removeItem('token');
        window.location.href = window.location.origin + '/admin/login';
    }
    return Promise.reject(error);
});

export default axios;
